<template>
  <div class="weui-tab">
    <div class="weui-tab__bd public-container-no-tarbar">
      <div class="">
        <div class="public-login-logo-box margin-bottom-10 margin-top-10">
          <img class="logo" alt="调研说" src="../assets/logo.svg" />
        </div>
        <div
          class="public-areas public-area-user-list public-login-form margin-bottom-10 margin-top-10"
        >
          <div class="weui-cells margin-top-0">
            <div class="weui-cell" href="javascript:;">
              <div class="weui-cell__hd">
                <label class="weui-label">手机号</label>
              </div>
              <div class="weui-cell__bd">
                <p>
                  <input
                    class="weui-input number-input"
                    v-model="mobile"
                    type="number"
                    maxlength="11"
                    placeholder="请输入手机号"
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="public-areas public-area-user-list public-login-form margin-bottom-10 margin-top-10"
        >
          <div class="weui-cells margin-top-0">
            <div class="weui-cell" href="javascript:;">
              <div class="weui-cell__hd">
                <label class="weui-label">验证码</label>
              </div>
              <div class="weui-cell__bd">
                <p>
                  <input
                    class="weui-input"
                    v-model="captcha"
                    type="text"
                    maxlength="6"
                    placeholder="请输入短信验证码"
                  />
                </p>
              </div>
              <div class="weui-cell__ft" v-if="!countDownStatus">
                <button class="weui-vcode-btn public-btn-vcode btn-verify-reg" @click="doGetSmsCaptcha">
                  获取验证码
                </button>
              </div>
              <div class="weui-cell__ft" v-else>
                <span class="weui-vcode-btn btn-verify-new public-btn-vcode disabled">{{seconds}}s 后发送</span>
              </div>
            </div>
          </div>
        </div>
        <div
          class="public-areas public-area-user-list public-login-form margin-bottom-10 margin-top-10"
        >
          <div class="weui-cells margin-top-0">
            <div class="weui-cell" href="javascript:;">
              <div class="weui-cell__hd">
                <label class="weui-label">密码</label>
              </div>
              <div class="weui-cell__bd">
                <p>
                  <input
                    class="weui-input number-input"
                    v-model="password"
                    type="password"
                    maxlength=""
                    placeholder="请输入密码"
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="public-area-submit-box">
          <div class="weui-cells margin-top-0">
            <div class="weui-cell">
              <div class="weui-cell__bd">
                <a href="javascript:;" @click="willRegister" class="weui-btn weui-btn_primary public-btn-register"
                  >注&nbsp;&nbsp;册</a
                >
              </div>
            </div>
            <div class="weui-cell">
              <div class="weui-cell__bd">
                <a @click="goLogin" class="public-login-register">密码登录</a>
              </div>
              <!-- <div class="weui-cell__ft public-register-statement">
                注册即同意 <a href="/m/agreement" class="">用户协议</a>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sha256 from 'sha256'
import { isInWeiXin } from '../utils/index'
import { mobileRegister, getSmsCaptcha } from '../api/index'
// import { getSmsCaptcha } from '../api/index'
import { getParams } from '../utils/index'

export default {
  name: "register",
  data () {
    return {
      mobile: '',
      password: '',
      captcha: '', // 相应手机号收到的验证码
      captchaUniqueId: '', // 验证码对应的唯一标识
      bizCode: 'Register', // 
      countDownStatus: false, // 发送验证码的60秒倒计时内
      timer: null,
      seconds: 60,
      inviterCode: '', // 邀请人代码
      fromName: '', // 上个页面的名称
      isWx: isInWeiXin()
    }
  },
  beforeRouteEnter (to, from, next) {
    console.log('from', from)
    next((vm) => {
      vm.fromName = from.name
    })
  },
  created () {
    this.urlParamsInit()
    localStorage.removeItem('SURVEY_token')
  },
  mounted () {
  },
  methods: {
    willRegister () {
      if ( !/^1\d{10}$/.test(this.mobile) ) {
        this.$toast.info('请输入正确的手机号码')
        return false
      }
      if (!this.captchaUniqueId) {
        this.$toast.info('请先发送手机验证码')
        return false
      }
      if (this.captcha.length < 6) {
        this.$toast.info('请输入正确的验证码')
        return false
      }
      if (this.password.length < 6) {
        this.$toast.info('请输入最少为6位的密码')
        return false
      }
      this.doMobileRegister()
    },
    // 发送验证码
    async doGetSmsCaptcha () {
      let params = {
        param: {
          mobile: this.mobile,
          bizCode: this.bizCode
        }
      }
      let res = {}
      try {
        res = await getSmsCaptcha(params)
        this.$toast.loading('加载中');
        if (res.code === '0000') {
          this.$toast.hide()
          const result = res.result
          this.captchaUniqueId = result.uniqueId
          this.$toast.info('验证码已发送，请查收')
          this.countDown()
        } else if (res.code === 'BIZ_1008') {
          this.$toast.info('该手机号已经注册，请直接登录。')
        } else {
          this.$toast.info(res.message)
          console.log('活动火爆，请稍后重试')
        }
      } catch (err) {
        this.$toast.hide()
        console.log('err:', err)
      }
    },
    // 注册
    async doMobileRegister () {
      const pwd = sha256(this.password)
      let params = {
        param: {
          mobile: this.mobile,
          password: pwd,
          captchaUniqueId: this.captchaUniqueId,
          captcha: this.captcha,
          inviterCode: this.inviterCode
        }
      }
      if (!this.inviterCode) {
        delete params.param.inviterCode
      }
      let res = {}
      try {
        res = await mobileRegister(params)
        this.$toast.loading('加载中', { duration: 0 });
        if (res.code === '0000') {
          this.$toast.hide()
          this.$toast.info('注册成功')
          const token = res.result.token
          localStorage.setItem('SURVEY_token', token)
          setTimeout(() => {
            if (getParams('returnUrl')) {
              const url = getParams('returnUrl')
              location.href = url
            } else {
              this.$router.push({ name: 'home' })
            }
          })
        } else if (res.code === 'BIZ_1008') {
          this.$toast.info('该手机号已经注册，请直接登录。')
        } else {
          this.$toast.hide()
          this.$toast.info(res.message)
        }
      } catch (err) {
        this.$toast.hide()
        console.log('err:', err)
      }
    },
    // 对URL上的参数进行初始化
    urlParamsInit () {
      let inviterCode = ''
      if (getParams('inviterCode')) {
        inviterCode = getParams('inviterCode')
      } else if (getParams('recordIdentifier')) {
        inviterCode = getParams('recordIdentifier')
      }
      if (inviterCode) {
        this.inviterCode = inviterCode
      }
    },
    // 跳转登录页面
    goLogin () {
      // if (this.fromName === 'login' && window.history.length > 1) {
      //   this.$router.back()
      // } else {
      //   const returnUrl = getParams('returnUrl')
      //   const inviterCode = this.inviterCode
      //   let query = {}
      //   if (returnUrl) {
      //     query = Object.assign(query, {returnUrl})
      //   }
      //   if (inviterCode) {
      //     query = Object.assign(query, {inviterCode})
      //   }
      //   console.log('query:', query)
      //   this.$router.push({name: 'login', query})
      // }
      const returnUrl = getParams('returnUrl')
        const inviterCode = this.inviterCode
        let query = {}
        if (returnUrl) {
          query = Object.assign(query, {returnUrl})
        }
        if (inviterCode) {
          query = Object.assign(query, {inviterCode})
        }
        console.log('query:', query)
        this.$router.push({name: 'login', query})
    },
    countDown () {
      this.countDownStatus = true
      this.timer = setInterval(() => {
        this.seconds--
        if (this.seconds === 0) {
          this.countDownStatus = false
          this.seconds = 60
          clearInterval(this.timer)
        }
      }, 1000)
    },
    showNotice () {
      this.$dialog.show({
        title: '提示',
        content: '请在微信内搜索“每日调查任务”公众号，进行密码修改。',
        showCancel: false,
        okText: "确定"
      })
    }
  },
};
</script>
<style lang="css" scoped>
.public-container-no-tarbar{
  margin: unset;
}
.weui-btn.public-btn-register{
  padding: unset;
}
</style>
